// import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, lruMemoize, createSelector } from 'reselect';
import {
  getLocalizedElement,
  getTranslationsForLanguage,
  objectValuesToString,
  validateOptions,
} from './utils';
import { flatten } from './flatten';

/**
 * TYPES
 */

interface BaseAction<T, P> {
  type: T;
  payload: P;
}

export type Action = BaseAction<
  string,
  InitializePayload &
    AddTranslationForLanguagePayload &
    SetActiveLanguagePayload &
    SetLanguagesPayload
>;

export interface Language {
  name?: string;
  code: string;
  active: boolean;
}

interface NamedLanguage {
  name: string;
  code: string;
}

type MissingTranslationCallback = (key: string, languageCode: string) => any;

export interface Translations {
  [key: string]: string[];
}

export type TranslatedLanguage = Record<string, string>;

type TransFormFunction = (
  data: any, // used to be type Object.
  languageCodes: string[],
) => Translations;

export interface LocaleState {
  languages: Language[];
  translations: Translations;
  options: Options;
}

export type TranslatePlaceholderData = Record<string, string | number>;

type LocalizedElementMap = Record<string, LocalizedElement>;

export type LocalizedElement = any | string; // Element<'span'>

type TranslateValue = string | string[];

export type TranslateFunction = (
  value: TranslateValue,
  data?: TranslatePlaceholderData,
  options?: Options,
) => LocalizedElement | LocalizedElementMap;

export interface Options {
  defaultLanguage?: string;
  showMissingTranslationMsg?: boolean;
  missingTranslationMsg?: string;
  missingTranslationCallback?: MissingTranslationCallback;
  translationTransform?: TransFormFunction;
  ignoreTranslateChildren?: boolean;
}

interface SingleLanguageTranslation {
  [key: string]: any | string; // used to be type Object.
}

interface SetLanguagesPayload {
  languages: Array<string | NamedLanguage>;
  activeLanguage?: string;
}

interface InitializePayload {
  languages: Array<string | NamedLanguage>;
  options?: Options;
}

interface BaseAction<T, P> {
  type: T;
  payload: P;
}

interface AddTranslationForLanguagePayload {
  translation: any; // used to be type Object.
  language: string;
}

interface SetActiveLanguagePayload {
  languageCode: string;
}

export type SetLanguagesAction = BaseAction<
  '@@localize/SET_LANGUAGES',
  SetLanguagesPayload
>;

type InitializeAction = BaseAction<'@@localize/INITIALIZE', InitializePayload>;

type AddTranslationForLanguageAction = BaseAction<
  '@@localize/ADD_TRANSLATION_FOR_LANGUGE',
  AddTranslationForLanguagePayload
>;
export type SetActiveLanguageAction = BaseAction<
  '@@localize/SET_ACTIVE_LANGUAGE',
  SetActiveLanguagePayload
>;

export type ActionDetailed = Action & {
  languageCodes: string[];
  translationTransform: TransFormFunction | undefined;
};

/**
 * ACTIONS
 */
const INITIALIZE = '@@localize/INITIALIZE';
export const SET_LANGUAGES = '@@localize/SET_LANGUAGES';
export const SET_ACTIVE_LANGUAGE = '@@localize/SET_ACTIVE_LANGUAGE';
export const ADD_TRANSLATION = '@@localize/ADD_TRANSLATION';
const ADD_TRANSLATION_FOR_LANGUGE = '@@localize/ADD_TRANSLATION_FOR_LANGUGE';

/**
 * REDUCERS
 */
export function translations(
  state: Translations = {},
  action: ActionDetailed,
): Translations {
  switch (action.type) {
    case ADD_TRANSLATION:
      // apply transformation if set in options
      const translations =
        action.translationTransform !== undefined
          ? action.translationTransform(
              action.payload.translation,
              action.languageCodes,
            )
          : action.payload.translation;
      return {
        ...state,
        ...flatten(translations, { safe: true }),
      };
    case ADD_TRANSLATION_FOR_LANGUGE:
      const languageIndex = action.languageCodes.indexOf(
        action.payload.language,
      );
      const flattenedTranslations =
        languageIndex >= 0 ? flatten(action.payload.translation) : {};
      // convert single translation data into multiple
      const languageTranslations = Object.keys(flattenedTranslations).reduce(
        (prev, cur: string) => {
          // loop through each language, and for languages that don't match active language
          // keep existing translation data, and for active language store new translation data
          const translationValues = action.languageCodes.map((code, index) => {
            const existingValues = state[cur] || [];
            return index === languageIndex
              ? // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                flattenedTranslations[cur]
              : existingValues[index];
          });
          return {
            ...prev,
            [cur]: translationValues,
          };
        },
        {},
      );

      return {
        ...state,
        ...languageTranslations,
      };
    default:
      return state;
  }
}

export function languages(state: Language[] = [], action: Action): Language[] {
  switch (action.type) {
    case INITIALIZE:
    case SET_LANGUAGES:
      const options = action.payload.options || {};
      const activeLanguage =
        action.payload.activeLanguage || options.defaultLanguage;
      return action.payload.languages.map((language, index) => {
        const isActive = (code: string) => {
          return activeLanguage !== undefined
            ? code === activeLanguage
            : index === 0;
        };
        // check if it's using array of Language objects, or array of languge codes
        return typeof language === 'string'
          ? { code: language, active: isActive(language) } // language codes
          : { ...language, active: isActive(language.code) }; // language objects
      });
    case SET_ACTIVE_LANGUAGE:
      return state.map((language) => {
        return language.code === action.payload.languageCode
          ? { ...language, active: true }
          : { ...language, active: false };
      });
    default:
      return state;
  }
}

export const defaultTranslateOptions: Options = {
  showMissingTranslationMsg: true,
  missingTranslationMsg:
    // eslint-disable-next-line no-template-curly-in-string
    'Missing translation key ${ key } for language ${ code } ', // fix key/code later
  ignoreTranslateChildren: false,
};

const initialState: LocaleState = {
  languages: [],
  translations: {},
  options: defaultTranslateOptions,
};
export function options(
  state: Options = defaultTranslateOptions,
  action: Action,
): Options {
  switch (action.type) {
    case INITIALIZE:
      const options = action.payload.options || {};
      return {
        ...state,
        ...validateOptions(options),
      };
    default:
      return state;
  }
}

export const localeReducer = (
  state: LocaleState = initialState,
  action: Action,
): LocaleState => {
  const languageCodes = state.languages.map((language) => language.code);
  const translationTransform = state.options.translationTransform;
  return {
    languages: languages(state.languages, action),
    translations: translations(state.translations, {
      ...action,
      languageCodes,
      translationTransform,
    }),
    options: options(state.options, action),
  };
};

/**
 * ACTION CREATORS
 */
export const initialize = (
  languages: Array<string | NamedLanguage>,
  options: Options = defaultTranslateOptions,
): InitializeAction => ({
  type: INITIALIZE,
  payload: { languages, options },
});

export const addTranslationForLanguage = (
  translation: SingleLanguageTranslation,
  language: string,
): AddTranslationForLanguageAction => ({
  type: ADD_TRANSLATION_FOR_LANGUGE,
  payload: { translation, language },
});

export const translationsEqualSelector = createSelectorCreator(
  lruMemoize,
  (cur, prev) => {
    const prevKeys: any =
      typeof prev === 'object' ? Object.keys(prev).toString() : undefined;
    const curKeys: any =
      typeof cur === 'object' ? Object.keys(cur).toString() : undefined;

    const prevValues: any =
      typeof prev === 'object' ? objectValuesToString(prev) : undefined;
    const curValues: any =
      typeof cur === 'object' ? objectValuesToString(cur) : undefined;

    const prevCacheValue =
      !prevKeys || !prevValues ? `${prevKeys} - ${prevValues}` : prev;

    const curCacheValue =
      !curKeys || !curValues ? `${curKeys} - ${curValues}` : cur;

    return prevCacheValue === curCacheValue;
  },
);

export const setActiveLanguage = (
  languageCode: string,
): SetActiveLanguageAction => ({
  type: SET_ACTIVE_LANGUAGE,
  payload: { languageCode },
});

/**
 * SELECTORS
 */

export const getTranslations = (state: LocaleState): Translations =>
  state.translations;

export const getLanguages = (state: LocaleState): Language[] => state.languages;

export const getActiveLanguage = (state: LocaleState): Language => {
  const languages = getLanguages(state);
  // @ts-expect-error-auto TS(2322) FIXME: Type 'Language | undefined' is not assignable to t... Remove this comment to see the full error message
  return languages.filter((language) => language.active === true)[0];
};

export const getOptions = (state: LocaleState): Options => state.options;

export const getTranslationsForActiveLanguage = translationsEqualSelector(
  getActiveLanguage,
  getLanguages,
  getTranslations,
  getTranslationsForLanguage,
);

export const getTranslationsForSpecificLanguage = translationsEqualSelector(
  getLanguages,
  getTranslations,
  (languages, translations) =>
    lruMemoize((languageCode) =>
      getTranslationsForLanguage(languageCode, languages, translations),
    ),
);

export const getTranslate = createSelector(
  getTranslationsForActiveLanguage,
  getTranslationsForSpecificLanguage,
  getActiveLanguage,
  getOptions,
  (
    translationsForActiveLanguage,
    getTranslationsForLanguage,
    activeLanguage,
    options,
  ) => {
    return (
      value: string | string[],
      data: TranslatePlaceholderData = {},
      optionsOverride: Options = {},
    ) => {
      const { defaultLanguage, ...rest } = optionsOverride;
      const translateOptions: Options = { ...options, ...rest };
      const translations =
        defaultLanguage !== undefined
          ? getTranslationsForLanguage({
              code: defaultLanguage,
              active: false,
            })
          : translationsForActiveLanguage;
      if (typeof value === 'string') {
        return getLocalizedElement(
          value,
          translations,
          data,
          activeLanguage,
          translateOptions,
        );
      } else if (Array.isArray(value)) {
        return value.reduce((prev, cur) => {
          return {
            ...prev,
            [cur]: getLocalizedElement(
              cur,
              translations,
              data,
              activeLanguage,
              translateOptions,
            ),
          };
        }, {});
      } else {
        throw new Error(
          'react-localize-redux: Invalid key passed to getTranslate.',
        );
      }
    };
  },
);
