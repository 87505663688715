import {
  createCoreClient,
  createTrackingClient,
  setupHttp,
} from '@mentimeter/http-clients';
import { userCache } from '@mentimeter/user';

const SERVICE_NAME = process.env.NEXT_PUBLIC_SERVICE_NAME as string;

const isServer = typeof window === 'undefined';

const token = isServer ? undefined : userCache.getToken();
const trackingToken = isServer ? undefined : userCache.getTrackingToken();
const defaultRegion = userCache?.region ?? 'eu';

setupHttp({
  serviceName: SERVICE_NAME,
  core: {
    defaultRegion,
    baseUrls: {
      eu: process.env.NEXT_PUBLIC_EU_CORE_URL as string,
      us: process.env.NEXT_PUBLIC_US_CORE_URL as string,
    },
    createClient(baseURL) {
      return createCoreClient({ baseURL, timeout: 40000, token });
    },
  },
  tracking: {
    defaultRegion,
    baseUrls: {
      eu: process.env.NEXT_PUBLIC_EU_TRACKING_URL as string,
      us: process.env.NEXT_PUBLIC_US_TRACKING_URL as string,
    },
    createClient(baseURL) {
      return createTrackingClient({
        baseURL,
        token: trackingToken,
        timeout: 40000,
      });
    },
  },
});
