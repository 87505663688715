'use client';
import { setUser as setSentryUser } from '@sentry/nextjs';
import User from '@mentimeter/user';

if (User) {
  setSentryUser(User.id ? { id: String(User.id) } : null);
}

export default function Sentry() {
  return null;
}
