import { createReducer } from '@reduxjs/toolkit';
import type * as Types from './types';
import { locationChange, toggleEndStatus } from './actions';

const initialState: Types.PresentationT = {
  showEndStatus: false,
};

export const presentationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(locationChange, (state) => {
      // We need to reset the showEndStatus flag when the location changes so it doesn't show up in the editor
      state.showEndStatus = false;
    })
    .addCase(toggleEndStatus, (state, action) => {
      state.showEndStatus = action.payload;
    }),
);
