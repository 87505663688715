/* eslint-disable eqeqeq */
/* eslint-disable no-eq-null */

interface FlattenOpts {
  delimiter?: string;
  maxDepth?: number;
  safe?: boolean;
}

export function flatten(target: any, opts: FlattenOpts = {}) {
  const { safe = false, delimiter = '.', maxDepth } = opts;
  const output = {};

  function step(object: Record<any, any>, prev = '', currentDepth = 1) {
    Object.keys(object).forEach((key) => {
      const value = object[key];
      const isarray = safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev ? prev + delimiter + key : key;

      if (
        !isarray &&
        !isbuffer &&
        isobject &&
        Object.keys(value).length &&
        (!maxDepth || currentDepth < maxDepth)
      ) {
        return step(value, newKey, currentDepth + 1);
      }

      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      output[newKey] = value;
    });
  }

  step(target);

  return output;
}

function isBuffer(obj: { constructor: { isBuffer: (arg0: any) => any } }) {
  return (
    obj != null &&
    obj.constructor != null &&
    typeof obj.constructor.isBuffer === 'function' &&
    obj.constructor.isBuffer(obj)
  );
}
