'use client';

// This import must go at the top
import 'lib/http/setup';

import i18n from '@mentimeter/i18n/i18nextInit';
import {
  i18nextConfig,
  addResourceBundles,
  MentimeterI18nInitializer,
  registerResourceBundles,
} from '@mentimeter/i18n';
import { locales as authLocales } from '@mentimeter/auth';
import Cookies from '@mentimeter/cookies';
import { HotkeyProvider } from '@mentimeter/hotkeys';
import { locales as plansContentLocales } from '@mentimeter/plans-content';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { createDscFromThemeSetting } from '@mentimeter/ragnar-ui';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { SSEProvider } from '@mentimeter/server-sent-events';
import { ToastsProvider } from '@mentimeter/toast';
import { parseAndStoreUtmParams } from '@mentimeter/utm';
import { store } from 'features/core/edit_view/store/configureStore';
import { useEffect } from 'react';
import { ThemeProvider } from 'react-fela'; // keep import from react-fela to avoid getting Tailwind theme twice
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { FelaProvider } from './FelaProvider';
import { RegionalProvider } from './RegionalProvider';
import { MobileMenuProvider } from './MobileMenuProvider';

// Register translations for auth package
registerResourceBundles(authLocales);

// Add plans specific translations to the i18n static instance
addResourceBundles(i18n, [plansContentLocales]);

export function Providers({ children }: { children: React.ReactNode }) {
  const uiTheme = Cookies.get('ui-theme');
  const { theme } = createDscFromThemeSetting(uiTheme);

  const breakpoints = theme.breakpoints.map((b) => stripAllNonNumericChars(b));

  useEffect(() => {
    parseAndStoreUtmParams(document.URL);
  }, []);

  return (
    <DeviceProvider breakpoints={breakpoints}>
      <ThemeProvider theme={theme}>
        <MobileMenuProvider>
          <FelaProvider>
            <HotkeyProvider>
              <RegionalProvider>
                <SSEProvider>
                  <ToastsProvider>
                    <ReduxProvider store={store}>
                      <I18nextProvider i18n={i18n}>
                        <MentimeterI18nInitializer
                          config={i18nextConfig}
                          language={i18n.language}
                        >
                          {children}
                        </MentimeterI18nInitializer>
                      </I18nextProvider>
                    </ReduxProvider>
                  </ToastsProvider>
                </SSEProvider>
              </RegionalProvider>
            </HotkeyProvider>
          </FelaProvider>
        </MobileMenuProvider>
      </ThemeProvider>
    </DeviceProvider>
  );
}
