import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { presentationReducer } from 'features/core/editor/state/presentation/reducer';
import { localeReducer } from 'lib/react-localize-redux/locale';

function createStore() {
  return configureStore({
    reducer: {
      // @ts-expect-error-auto TS(2322) FIXME: Type '(state: LocaleState | undefined, action: Act... Remove this comment to see the full error message
      locale: localeReducer,
      presentation: presentationReducer,
    },
    // @ts-expect-error-auto TS(2322) FIXME: Type '(getDefaultMiddleware: CurriedGetDefaultMidd... Remove this comment to see the full error message
    middleware: (getDefaultMiddleware) =>
      [
        ...getDefaultMiddleware({
          serializableCheck: false, // TODO: We should aim to enable this (?)
          immutableCheck: process.env.NODE_ENV !== 'test', // disable this in tests since its too slow
        }),
        createListenerMiddleware().middleware,
      ].filter(Boolean),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

export const store = createStore();
