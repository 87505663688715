'use client';
import { useState, useRef } from 'react';
import { useServerInsertedHTML } from '@mentimeter/next-navigation';
import {
  createRenderer as felaCreateRenderer,
  renderToNodeList,
  RendererProvider,
  prefixer,
  fallback,
} from '@mentimeter/ragnar-react';
import * as Fonts from '@mentimeter/ragnar-fonts';
import { designSystemConfig as DSC } from '@mentimeter/ragnar-dsc';
import { reset, setup } from '@mentimeter/ragnar-reset';
import { loadEditorFonts } from '@mentimeter/ragnar-fonts';
import { CSS as cropperCSS } from '@mentimeter/cropper';

export function FelaProvider({ children }: { children: React.ReactNode }) {
  const hasInsertedHTML = useRef(false);
  const [renderer] = useState(() => createRenderer());

  useServerInsertedHTML(() => {
    if (!hasInsertedHTML.current) {
      hasInsertedHTML.current = true;
      return renderToNodeList(renderer);
    }
    return null;
  });
  return <RendererProvider renderer={renderer}>{children}</RendererProvider>;
}

function createRenderer(): any {
  const renderer = felaCreateRenderer({
    plugins: [loadEditorFonts, prefixer(), fallback()],
    selectorPrefix: 'f-',
    mediaQueryOrder: DSC.breakpoints.map(
      (bp) => `screen and (min-width: ${bp})`,
    ),
  });

  renderer.renderStatic(`*:focus { outline: none }`);
  renderer.renderStatic(cropperCSS);

  Object.keys(Fonts.MentiText).forEach((key) => {
    // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const item = Fonts.MentiText[key];
    renderer.renderFont('MentiText', item.files, item.style);
  });

  Object.keys(Fonts.MentiDisplay).forEach((key) => {
    // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const item = Fonts.MentiDisplay[key];
    renderer.renderFont('MentiDisplay', item.files, item.style);
  });

  renderer.renderStatic(reset(setup('#__next', false)));
  return renderer;
}
