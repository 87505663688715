import type {
  Language,
  LocalizedElement,
  Options,
  TranslatedLanguage,
  TranslatePlaceholderData,
  Translations,
} from './locale';
import { defaultTranslateOptions } from './locale';

export const getLocalizedElement = (
  key: string,
  translations: TranslatedLanguage,
  data: TranslatePlaceholderData,
  activeLanguage: Language,
  options: Options = defaultTranslateOptions,
): LocalizedElement => {
  const onMissingTranslation = () => {
    if (options.missingTranslationCallback) {
      options.missingTranslationCallback(key, activeLanguage.code);
    }
    return options.showMissingTranslationMsg === false
      ? ''
      : templater(options.missingTranslationMsg || '', {
          key,
          code: activeLanguage.code,
        });
  };
  const localizedString = translations[key] || onMissingTranslation();
  return templater(localizedString, data);
};

/**
 * @func templater
 * @desc A poor mans template parser
 * @param {string} strings The template string
 * @param {TranslatePlaceholderData} data The data that should be inserted in template
 * @return {string} The template string with the data merged in
 */
export const templater = (
  templateString: string,
  data: TranslatePlaceholderData = {},
): string => {
  let strings = templateString;
  for (const key in data) {
    const pattern = '\\${\\s*' + key + '\\s*}';
    const regex = new RegExp(pattern, 'gmi');
    strings = strings.replace(regex, data[key]!.toString());
  }
  return strings;
};

export const validateOptions = (options: Options): Options => {
  if (
    options.translationTransform !== undefined &&
    typeof options.translationTransform !== 'function'
  ) {
    throw new Error(
      'react-localize-redux: Invalid translationTransform function.',
    );
  }
  return options;
};

export const objectValuesToString = (data: any): string => {
  return !Object.values
    ? Object.keys(data)
        .map((key) => data[key].toString())
        .toString()
    : Object.values(data).toString();
};

export const getIndexForLanguageCode = (
  code: string,
  languages: Language[],
): number => {
  return languages.map((language) => language.code).indexOf(code);
};

export const getTranslationsForLanguage = (
  language: Language,
  languages: Language[],
  translations: Translations,
): TranslatedLanguage => {
  // no language! return no translations
  if (!language) {
    return {};
  }

  const { code: languageCode } = language;
  const languageIndex = getIndexForLanguageCode(languageCode, languages);
  return Object.keys(translations).reduce((prev, key) => {
    return {
      ...prev,
      [key]: translations?.[key]?.[languageIndex],
    };
  }, {});
};
